import React, {createContext, useState, Children, ReactElement, useEffect, isValidElement} from 'react';
import {StepContextType, StepsManagerDataHook, StepsMangerProps} from '../types';

export const StepsContext = createContext({} as StepContextType);

export const StepsManger = ({children, onStepChange}: StepsMangerProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const actualChildren = children.filter((child) => !!child) as React.ReactElement[];

  const getStepName = (stepIndex: number) => {
    const step = actualChildren[stepIndex];
    return isValidElement(step) && typeof step.type === 'function'
      ? step.type.name
      : /* istanbul ignore next: Mandatory check, StepManager gets only valid children */ null;
  };

  const onStepCompleted = () => {
    setActiveStep(activeStep + 1);
  };

  const onStepEdit = (stepIndex: number) => {
    setActiveStep(stepIndex);
  };

  useEffect(() => {
    onStepChange(activeStep, getStepName(activeStep));
  }, [activeStep]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StepsContext.Provider value={{activeStep, onStepEdit, onStepCompleted}}>
      <div data-hook={StepsManagerDataHook.root}>
        {Children.map(actualChildren, (child: ReactElement, index) =>
          React.cloneElement(child, {
            index,
            isLastStep: actualChildren.length === index + 1,
          })
        )}
      </div>
    </StepsContext.Provider>
  );
};

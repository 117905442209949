/* istanbul ignore file: test forms */
import {IOpenComponentProps, PaymentStepDataHook} from '../../../types';
import React, {useRef, useState} from 'react';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {NextStepButton} from '../../../StepsManager/Components/NextStepButton/NextStepButton';
import {PaymentsWidget} from '@wix/cashier-payments-widget/lazy';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {BillingDetails} from './BillingDetails/BillingDetails';
import {getContactDetailsFromContactFormValues, getContactFormInitialState} from '../../../../Form/ContactForm';
import {FormValues} from '@wix/form-viewer';
import {FormViewerHandle} from '@wix/form-viewer/dist/types/tpa/widget';
import {cashierMandatoryFieldPlaceholder} from '../../../../../../domain/utils/cashierMandatoryFieldPlaceholder';

export const PaymentOpen = ({onCompleted}: IOpenComponentProps) => {
  const localeKeys = useLocaleKeys();
  const {
    paymentStore: {cashierConfiguration},
    checkoutStore: {setBillingContactDetails, checkout},
    checkoutSettingsStore: {checkoutSettings},
  } = useControllerProps();

  const [contactFormValues, setContactFormValues] = useState<FormValues>(
    getContactFormInitialState(checkoutSettings, checkout.billingInfo?.contact)
  );

  const contactFormViewer = useRef<FormViewerHandle>(null);
  const formRefs = [contactFormViewer];

  const validateAndSubmit = async () => {
    const areFormsValidArr = await Promise.all(
      formRefs.filter((ref) => !!ref.current).map((ref) => ref.current!.validate())
    );

    if (!areFormsValidArr.includes(false)) {
      await submitBillingDetails();
      onCompleted();
    }
  };

  const submitBillingDetails = async () => {
    await setBillingContactDetails(
      getContactDetailsFromContactFormValues(contactFormValues, checkoutSettings, checkout.billingInfo?.contact)
    );
  };

  return (
    <div data-hook={PaymentStepDataHook.open}>
      {cashierConfiguration && (
        <PaymentsWidget
          configuration={cashierConfiguration}
          locale={'en'}
          isWidgetVisible={true}
          theme={'default'}
          shouldApplySiteStyles={false}
          externalSubmitButton={true}
          paymentMethodChanged={/* istanbul ignore next */ (_paymentMethodId) => undefined}
          mandatoryFields={cashierMandatoryFieldPlaceholder}
          paymentResult={/* istanbul ignore next */ () => null}
          walletPaymentInNewTab={false}
          isSaveCCEnabled={false}
          allowManualPayment={true}
          allowRecurringPaymentOnly={false}
        />
      )}
      <BillingDetails
        setContactFormValues={setContactFormValues}
        contactFormValues={contactFormValues}
        contactFormViewer={contactFormViewer}
      />
      <NextStepButton
        onClick={() => void validateAndSubmit()}
        text={localeKeys.checkout.continue_button_label()}
        dataHook={PaymentStepDataHook.continueButton}
      />
    </div>
  );
};

/* istanbul ignore file: test forms */
import {CustomerDetailsStepDataHook, IOpenComponentProps} from '../../../types';
import React, {useRef, useState} from 'react';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {NextStepButton} from '../../../StepsManager/Components/NextStepButton/NextStepButton';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {FormError, FormValues, FormViewerHandle} from '@wix/form-viewer/widget';
import {classes} from './CustomerDetailsOpen.st.css';
import {EmailForm, getEmailFormInitialState, getEmailFromEmailFormValues} from '../../../../Form/EmailForm';
import {
  ContactForm,
  getContactDetailsFromContactFormValues,
  getContactFormInitialState,
} from '../../../../Form/ContactForm';
import {
  AdditionalInfoForm,
  getAdditionalInfoFormInitialState,
  getCustomFieldFromAdditionalInfoFormValues,
} from '../../../../Form/AdditionalInfoForm';

export enum CustomerDetailsDataHook {
  emailForm = 'CustomerDetails.emailForm',
  contactForm = 'CustomerDetails.contactForm',
  additionalInfoForm = 'CustomerDetails.additionalInfoForm',
}

export const CustomerDetailsOpen = ({onCompleted}: IOpenComponentProps) => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {setShippingContactDetails, checkout},
    checkoutSettingsStore: {checkoutSettings},
  } = useControllerProps();

  const emailFormViewer = useRef<FormViewerHandle>(null);
  const [emailFormValues, setEmailFormValues] = useState<FormValues>(getEmailFormInitialState(checkout.buyerInfo));
  const [emailFormErrors, setEmailFormErrors] = useState<FormError[]>([]);

  const contactFormViewer = useRef<FormViewerHandle>(null);
  const [contactFormValues, setContactFormValues] = useState<FormValues>(
    getContactFormInitialState(checkoutSettings, checkout.shippingDestination?.contact)
  );
  const [contactFormErrors, setContactFormErrors] = useState<FormError[]>([]);

  const additionalInfoFormViewer = useRef<FormViewerHandle>(null);
  const [additionalInfoFormValues, setAdditionalInfoFormValues] = useState<FormValues>(
    getAdditionalInfoFormInitialState(checkout.customField)
  );
  const [additionalInfoFormErrors, setAdditionalInfoFormErrors] = useState<FormError[]>([]);

  const formRefs = [emailFormViewer, contactFormViewer, additionalInfoFormViewer];

  const submitContactDetails = async () => {
    const customFieldValue = checkoutSettings.customField.show
      ? getCustomFieldFromAdditionalInfoFormValues(additionalInfoFormValues)
      : undefined;
    await setShippingContactDetails({
      contactDetails: getContactDetailsFromContactFormValues(
        contactFormValues,
        checkoutSettings,
        checkout.shippingDestination?.contact
      ),
      email: getEmailFromEmailFormValues(emailFormValues),
      customFieldValue,
    });
  };

  const validateAndSubmit = async () => {
    const areFormsValidArr = await Promise.all(
      formRefs.filter((ref) => !!ref.current).map((ref) => ref.current!.validate())
    );

    if (!areFormsValidArr.includes(false)) {
      await submitContactDetails();
      onCompleted();
    }
  };

  return (
    <div data-hook={CustomerDetailsStepDataHook.open} className={classes.root}>
      <div data-hook={CustomerDetailsDataHook.emailForm}>
        <EmailForm
          formRef={emailFormViewer}
          formValues={emailFormValues}
          formErrors={emailFormErrors}
          setFormValues={setEmailFormValues}
          setFormErrors={setEmailFormErrors}
        />
      </div>
      <div data-hook={CustomerDetailsDataHook.contactForm}>
        <ContactForm
          formRef={contactFormViewer}
          formValues={contactFormValues}
          formErrors={contactFormErrors}
          setFormValues={setContactFormValues}
          setFormErrors={setContactFormErrors}
        />
      </div>
      {checkoutSettings.customField.show && (
        <div data-hook={CustomerDetailsDataHook.additionalInfoForm}>
          <AdditionalInfoForm
            formRef={additionalInfoFormViewer}
            formValues={additionalInfoFormValues}
            formErrors={additionalInfoFormErrors}
            setFormValues={setAdditionalInfoFormValues}
            setFormErrors={setAdditionalInfoFormErrors}
          />
        </div>
      )}
      <NextStepButton
        onClick={() => void validateAndSubmit()}
        text={localeKeys.checkout.continue_button_label()}
        dataHook={CustomerDetailsStepDataHook.continueButton}
      />
    </div>
  );
};

import React from 'react';

export interface IOpenComponentProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onCompleted: () => void;
}

export interface StepContextType {
  activeStep: number;
  onStepEdit: (stepIndex: number) => void;
  onStepCompleted: () => void;
}

export interface StepsMangerProps {
  children: (React.ReactElement | null)[];
  onStepChange: (stepId: number, stepName: string | null) => void;
}

export type stepImplementationProps = {
  index?: number;
  isLastStep?: boolean;
};

export type stepDataHook = {
  root: string;
  header: string;
  collapsed: string;
  open?: string;
  empty?: string;
  edit?: string;
  continue?: string;
  editButton?: string;
  divider?: string;
};

export enum CustomerDetailsStepDataHook {
  root = 'CustomerDetailsStep.root',
  header = 'CustomerDetailsStep.header',
  collapsed = 'CustomerDetailsStep.collapsed',
  open = 'CustomerDetailsStep.open',
  empty = 'CustomerDetailsStep.empty',
  edit = 'CustomerDetailsStep.edit',
  continueButton = 'CustomerDetailsStep.continue',
  editButton = 'CustomerDetailsStep.editButton',
  divider = 'CustomerDetailsStep.divider',
}

export enum PaymentStepDataHook {
  root = 'PaymentStep.root',
  header = 'PaymentStep.header',
  collapsed = 'PaymentStep.collapsed',
  open = 'PaymentStep.open',
  empty = 'PaymentStep.empty',
  continueButton = 'PaymentStep.continue',
  editButton = 'PaymentStep.editButton',
  divider = 'PaymentStep.divider',
}

export enum PlaceOrderDataHook {
  root = 'PlaceOrder.root',
  header = 'PlaceOrder.header',
  subtitle = 'PlaceOrder.subtitle',
  collapsed = 'PlaceOrder.collapsed',
  open = 'PlaceOrder.open',
  empty = 'PlaceOrder.empty',
  placeOrderButton = 'PlaceOrder.placeOrderButton',
  divider = 'PlaceOrder.divider',
}

export enum StepsManagerDataHook {
  root = 'StepsManager.root',
}
